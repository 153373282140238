import React from "react";
import { Link, graphql } from "gatsby";
import BaseLayout from "../components/BaseLayout"
import "../styles/global.css"; // add some style if you want!

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark;
  return (
    <BaseLayout >
    <div class="container w-full md:max-w-3xl">
          <div class="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal"></div>
            {posts
              .filter((post) => post.node.frontmatter.title.length > 0)
              .map((({ node: post }, index) => {
                return (
                  <div className="blog-post-preview" key={post.id}>
                    <div class="font-sans">
                      <h1 class="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">
                        <Link to={post.frontmatter.path}>
                          {post.frontmatter.title}
                        </Link>
                      </h1>
                      <p class="text-sm md:text-base font-normal text-gray-600 mb-4 mt-2">
                        {post.frontmatter.date}
                      </p>
                      {/* <div class="font font-sans break-normal text-gray-900 text-lg md:text-lg">
                        {post.excerpt}
                      </div> */}
                      {index < 8 ? <div
          
          dangerouslySetInnerHTML={{ __html: post.html }}
        /> :
        <>
        <div class="font font-sans break-normal text-gray-900 text-lg md:text-lg">
                        {post.excerpt}
                      </div> 
                      <a className="underline text-sm text-blue-900">
                        <Link to={post.frontmatter.path}>Read Post</Link>
                      </a>
                      </>}
                    </div>
                  </div>
                );
              }))}
              </div>
          </BaseLayout>
  );
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          html
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
          }
        }
      }
    }
  }
`;
